import zhLocale from "element-ui/lib/locale/lang/zh-CN"; //引入element语言包
const cn = {
  homeHeader: {
    dropdown: {
      dashboard: "面板",
      viewProfile: "个人中心",
      logout: "退出",
    },
    login: "登录",
    register: "注册",
  },
  footer: {
    info: "艺博科研学术出版社在新加坡正式成立，以全球为导向，积极面向全球开拓业务版图。其核心业务主要集中在出版学术期刊以及组织国际学术会议。此外，还广泛涵盖教育培训、科技信息咨询、翻译服务、抄袭检查服务以及出版电子书等多元化领域。",
    Address: "12 WOODLANDS SQUARE #12-85 WOODS SQUARE SINGAPORE 737715",
    tel: "+65 9863 3408",
    email: "yiborap@outlook.com",
    author:
      "Yibo Research Academic Press Pte Ltd © 2019-2025 All Right Reserved",
  },
  homeNav: [
    { name: "首页", path: "/" },
    { name: "期刊", path: "/journalList" },
    {
      name: "编辑和出版政策",
      path: "/editorialPublishing",
    },
    // { name: "Partners", path: "/" },
    { name: "新闻与公告", path: "/announcementsList" },
    { name: "关于我们", path: "/aboutView" },
  ],
  homeView: {
    banner: {
      title: "艺博科研学术出版社",
      subTitle:
        "艺博科研学术出版社在新加坡正式成立，以全球为导向，积极面向全球开拓业务版图。其核心业务主要集中在出版学术期刊以及组织国际学术会议。此外，还广泛涵盖教育培训,科技信息咨询,翻译服务,抄袭检查服务以及出版电子书等多元化领域。",
    },
    search: {
      placeholder: "请输入内容",
      journal: "期刊",
      article: "文章",
      keyword: "关键词",
    },
    content: {
      journal: "期刊",
      articles: "最新文章",
      more: "更多期刊",
    },
  },
  asidPart: {
    classification: "期刊",
    asidNavList: [
      // { navItem: "Information", url: "" },
      { navItem: "对于作者", url: "/forAuthors" },
      { navItem: "对于审稿人", url: "/forReviewers" },
      { navItem: "对于编辑", url: "/forEditors" },
    ],
    asidTitle: "新闻与公告",
    detail: "详情",
    more: "更多",
  },
  journalList: {
    title: "开放存取期刊",
    viewJournal: "查看期刊",
    currentIssue: "现刊",
  },
  abouView: {
    title: "关于艺博科研学术出版社",
    Singapore: "新加坡",
  },
  detailPages: {
    OpenAccess: "开放存取",
    PublicationFrequency: "出版频率",
    Online: "在线",
    Articles: "文章",
    onBoard: "上岗",
    editors: "编辑",
    placeholder: "请输入期刊",
    submit: "提交论文",
    menu: "期刊菜单",
    tableContent: "目录",
    aboutJournal: "关于期刊",
    indexAbstracting: "索引和摘要",
    articleTypeList: ["最新文章"],
    more: "更多",
    vol: "卷",
    No: "编号",
    view: "查看",
    downloads: "下载",
    readMore: "阅读更多",
    clickHide: "点击隐藏",
    pdfDownload: "PDF下载",
    Keywords: "关键词",
    downloadsPdf: "下载PDF",
    Abstract: "摘要",
    References: "工具书类",
    Published: "出版",
    cite: "如何引用",
    selectStyle: "选择引用样式",
    Issue: "期",
    Section: "类别",
    copyRight: "版权所有©2024作者。由艺博科研学术出版社出版。",
  },
  shareLink: "分享链接",
  copy: "复制",
  authorGuidelines: {
    checkList: "提交准备检查表",
    guidelines: "作者指南",
    copyright: "版权声明",
    statement: "隐私声明",
  },
  login: {
    title: "登录",
    username: "用户名",
    password: "密码",
    forgetPassword: "忘记密码?",
    register: "注册",
    keep: "保持登录",
    resetPassword: "重置密码",
    resetPasswordMessage:
      "确认已发送到您的电子邮件地址。请按照电子邮件中的说明重置密码。",
    restLabel:
      "在下面输入您的帐户电子邮件地址，系统将发送一封电子邮件，其中包含如何重置密码的说明。注册用户的电子邮件",
    restPlacdholder: "输入您的电子邮件",
  },
  register: {
    Profile: "简况",
    givenName: "名字",
    familyName: "姓氏",
    affiliation: "附属",
    country: "国家/地区",
    email: "邮箱",
    repeatPassword: "重复密码",
    Journal: "期刊",
    check: "是的，我希望收到新出版物和公告的通知。",
    login: "登录",
  },
  searchPage: {
    Title: "标题",
    Keywords: "关键词",
    Journal: "期刊",
    PublishedAfter: "发表后",
    PublishedBefore: "发表前",
    Author: "作者",
    UpdateSearching: "更新搜索",
    clear: "清空",
    articleTypeList: ["文章"],
  },
  form: {
    required: "必填项",
    email: "输入正确的电子邮件地址",
    url: "请输入有效的URL",
    passwordAgain: "请重新输入密码",
    passwordDiffer: "输入的密码不同！",
    passwordLength: "密码必须至少包含6个字符",
  },
  submission: {
    title: "投稿",
    search: "搜索",
    assigned: "我的分配",
    archived: "已存档的提交文件",
    newSubmission: "投稿",
    viewSubmission: "查看稿件",
    deleteSubmission: "删除",
    edit: "编辑",
    queue: "我的队列",
    Archives: "存档",
    Section: "分类",
    sectionLable: "文章必须提交到期刊的一个版块。",
    selectSection: "选择分类",
    sectionPolicy: "章节政策",
    Requirements: "稿件要求",
    helpText: "在继续之前，您必须阅读并确认您已完成以下要求。",
    Editor: "对编辑的评论",
    privacyConsent: "是的，我同意根据",
    privacyStatementLink: "隐私声明",
    privacyStatement: "收集和存储我的数据。",
    continue: "保存并继续",
    Cancel: "取消",
    secondTitle: "稿件文件",
    UploadFile: "上传文件",
    downloadAllFiles: "下载全部文件",
    viewMetadata: "查看元数据",
    editFile: "编辑文件",
    save: "保存",
    Continue: "继续",
    uploadSubmissionFile: "上传稿件文件",
    fileStepFirst: "1. 上传文件",
    fileStepSecond: "2. 查看详细信息",
    fileStepthird: "3. 确认",
    revisedLabel: "如果您正在上传现有文件的修订版，请注明是哪个文件。",
    revisedPlaceholder: "这不是对现有文件的修订",
    genrePlaceholder: "选择文章组件",
    Drag: "将文件拖到此处，或",
    clickUpload: "点击上传",
    fileAdd: "文件已添加",
    addAnotherFile: "添加另一个文件",
    Prefix: "前缀",
    Title: "标题",
    Subtitle: "副标题",
    listContributors: "贡献者",
    addContributor: "添加贡献者",
    editContributor: "编辑贡献者",
    fullName: "名字",
    email: "邮箱",
    userGroupName: "角色",
    primaryContact: "主要联系人",
    inBrowseLists: "在浏览列表中",
    SubmissionMetadata: "稿件元数据",
    specifications:
      "这些规范基于都柏林核心元数据集，这是一个用于描述期刊内容的国际标准。",
    additionalRefinements: "其他精炼",
    newKeywords: "新关键字",
    ProjectID: "OpenAIRE ProjectID",
    research: "如果这项研究是由符合",
    OpenAIRE: "OpenAIRE",
    platform:
      "元数据平台的欧盟倡议引起的，请包括项目ID，这是一个与赠款协议标识符相对应的六位数。",
    Salutations:
      "你更喜欢别人怎么称呼你？如果你愿意，可以在这里添加问候语、中间名和后缀。",
    publicName: "首选公共名称",
    Contact: "联系方式",
    userDetails: "用户详细信息",
    HomepageURL: "Homepage URL",
    ORCID: "请参阅下文以请求经过身份验证的ORCID iD",
    department: "简历（如部门和职级）",
    Translator: "翻译家",
    contributorRole: "贡献者的角色",
    Principal: "编辑信函的主要联系人。",
    browse: "是否将此贡献者包含在浏览列表中？",
    submit: "提交",
    Confirmation:
      '您的提交已上传，准备发送。在继续之前，您可以返回查看和调整您输入的任何信息。准备就绪后，单击“完成提交”".',
    finishSubmission: "完成提交",
    SubmissionComplete: "提交完成",
    Population: "感谢您对《全球人口展望》的出版兴趣。",
    Happens: "接下来会发生什么？",
    notified:
      "期刊已收到您提交的通知，并已通过电子邮件向您发送了记录确认。一旦编辑审阅了提交的内容，他们将与您联系。",
    youCan: "目前，您可以： ",
    Review: "查看此稿件",
    Create: "创建新稿件",
    returnDashboard: "返回面板",
    submitArticle: "提交文章",
    articleComponent: "文章组件",
    steps: {
      step1: "1. 开始",
      step2: "2. 上传稿件",
      step3: "3. 输入元数据",
      step4: "4. 确认",
      step5: "5. 下一步",
    },
    preDiscussions: "预审查讨论",
    ReviewDiscussions: "审查讨论",
    CopyeditingDiscussions: "编辑讨论",
    ProductionDiscussions: "制作讨论",
    addDiscussions: "新建讨论",
    From: "源于",
    lastReply: "最后回复",
    Replies: "答复",
    Closed: "关闭",
    Edit: "编辑",
    Stage: "阶段未启动。",
    reviewerAttachments: "审阅者附件",
    Revisions: "修订",
    Copyedited: "编辑",
    Galleys: "排版",
    stages: {
      Submission: "提交",
      Review: "评审",
      Copyediting: "文案编辑",
      Production: "制作",
      Published: "已出版",
      Declined: "已拒绝",
      Incomplete: "未完成",
    },
    discussion: {
      adddiscussion: "新建讨论",
      updateDiscussion: "编辑讨论",
      Participants: "参与者",
      Subject: "主题",
      message: "消息",
      secondTitle: "附件",
    },
    Comment: {
      Note: "记录",
      addMessage: "添加信息",
      Message: "信息",
    },
  },
  Prompt: {
    title: "提示",
    content: "此操作无法撤消。您想继续吗?",
    sure: "确认",
    cancel: "取消",
    success: "操作成功！",
    info: "已取消",
    imageType: "上传的头像图片只能是JPG/PNG格式！",
    imageSize: "上传的图片必须是 150x150 像素!",
  },
  profile: {
    statement: "您的数据将根据我们的隐私声明进行存储。",
    Signature: "签名",
    Phone: "电话",
    mailingAddress: "邮寄地址",
    Interests: "新兴趣",
    ReInterests: "审查利益",
    profileImage: "个人资料图片",
    passwords: "在下面输入您的当前密码和新密码，以更改您帐户的密码。",
    currentPassword: "当前密码",
    newPassword: "新密码",
    repeatPassword: "重复新密码",
    system:
      "选择您希望收到通知的系统事件。取消选中将阻止事件通知显示在系统中，也不会通过电子邮件发送给您。选中的事件将显示在系统中，您可以选择通过电子邮件接收或不接收相同的通知。",
    enablenotifications: "启用这些类型的通知。",
    notifications: "不要给我发这类通知的电子邮件。",
    Generate: "生成新的API密钥",
    invalidate: "生成新的API密钥将使此用户的任何现有密钥无效。",
    tab: {
      Identity: "身份",
      Contact: "联系方式",
      Roles: "角色",
      Public: "公共",
      Password: "密码",
      Notifications: "通知",
      apiKeys: "API密钥",
    },
  },
  empty: "暂无数据",
  menuList: [
    "编辑委员会",
    "关于期刊",
    "索引和归档",
    "联系信息",
    "作者指南",
    "文章处理费",
    "当前",
    "作者身份",
    "利益冲突",
    "确保盲目审查",
    "隐私声明",
  ],
  ...zhLocale, //这里引入element语言包目的是在切换语言的时候，element的组件里面的文字能跟随切换
};
// 导出
export default cn;
