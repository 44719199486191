<template>
  <div class="bottom-view">
    <div class="row">
      <div class="centerRow">
        <div class="info">
          {{ $t("footer.info") }}
        </div>
        <div class="message">
          <p>
            <i class="el-icon-location"></i>
            {{ $t("footer.Address") }}
          </p>
          <p>
            <i class="el-icon-phone"></i> Tel:
            {{ $t("footer.tel") }}
          </p>
          <p>
            <i class="el-icon-message"></i>
            E-mail:
            {{ $t("footer.email") }}
          </p>
        </div>
      </div>
    </div>
    <div class="centerBottom">
      {{ $t("footer.author") }}
      <!-- <div>
        
      </div> -->
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.bottom-view {
  position: absolute;
  bottom: 0 !important;
  width: 100%;
  padding: 15px 0;
  background: #626262;
  color: #ffffff;
  @media (max-width: 996px) {
    width: 100vw;
    padding: 15px 10px;
    box-sizing: border-box;
  }
  .row {
    width: 100%;
    padding-bottom: 20px;
    border-bottom: 1px solid #565656;
    margin-bottom: 20px;
    .centerRow {
      width: 100%;
      max-width: 1400px;
      margin: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media (max-width: 996px) {
        flex-direction: column;
        align-items: flex-start;
      }
      .info {
        width: 49%;
        @media (max-width: 996px) {
          width: 100%;
          margin-bottom: 10px;
        }
      }
      .message {
        width: 50%;
        @media (max-width: 996px) {
          width: 100%;
        }
        p {
          margin-bottom: 10px;
          i {
            margin-right: 5px;
          }
        }
      }
    }
  }
  .centerBottom {
    width: 1400px;
    margin: auto;
    max-width: 1400px;
    text-align: center;
    font-size: 16px;
    line-height: 30px;
    @media (max-width: 996px) {
      width: 100%;
      font-size: 13px;
      // height: 42px;
      line-height: 30px;
    }
  }
}
</style>
