import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/page/HomeView.vue";

Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/advancedSearch",
    name: "advancedSearch",
    component: () => import("../views/page/advancedSearch.vue"),
  },
  {
    path: "/journalList",
    name: "journalList",
    component: () => import("../views/page/journalList.vue"),
  },
  {
    path: "/journal/:path",
    name: "detailPages",
    component: () => import("../views/page/detailPages.vue"),
    redirect: "/journal/:path",
    //二级路由
    children: [
      {
        path: "/",
        name: "index",
        meta: {
          bread: [
            { name: "home", link: "/" },
            { name: "journals", link: "/journalList" },
          ],
        },
        component: () => import("../views/page/journalDetail/journalIndex"),
      },
      {
        path: "editorialBoard",
        name: "Editorial Board",
        meta: {
          bread: [
            { name: "home", link: "/" },
            { name: "journals", link: "/journalList" },
          ],
          current: [{ name: "Editorial Board" }],
        },
        component: () => import("../views/page/journalDetail/editorialTeam"),
      },
      {
        path: "aboutJournal",
        name: "About the Journal",
        meta: {
          bread: [
            { name: "home", link: "/" },
            { name: "journals", link: "/journalList" },
          ],
          current: [{ name: "About the Journal" }],
        },
        component: () => import("../views/page/journalDetail/aboutJournal"),
      },
      {
        path: "privacyStatement",
        name: "Privacy Statement",
        meta: {
          bread: [
            { name: "home", link: "/" },
            { name: "journals", link: "/journalList" },
          ],
          current: [{ name: "Privacy Statement" }],
        },
        component: () => import("../views/page/journalDetail/privacyStatement"),
      },
      {
        path: "indexing",
        name: "Indexing & Archiving",
        meta: {
          bread: [
            { name: "home", link: "/" },
            { name: "journals", link: "/journalList" },
          ],
          current: [{ name: "Indexing & Archiving" }],
        },
        component: () =>
          import("../views/page/journalDetail/IndexingArchiving"),
      },
      {
        path: "contact",
        name: "Contact Information",
        meta: {
          bread: [
            { name: "home", link: "/" },
            { name: "journals", link: "/journalList" },
          ],
          current: [{ name: "Contact Information" }],
        },
        component: () =>
          import("../views/page/journalDetail/contactInformation"),
      },
      // {
      //   path: 'downLoadPdf',
      //   name: 'downLoadPdf',
      //   meta: { bread: [{ name: "home", link: "/" }, { name: "journals", link: "/journalList" }], current: [{ name: "downLoadPdf" }] },
      //   component: () => import("../views/page/journalDetail/downLoadPdf")
      // },
      {
        path: "authorGuidelines",
        name: "Author Guidelines",
        meta: {
          bread: [
            { name: "home", link: "/" },
            { name: "journals", link: "/journalList" },
          ],
          current: [{ name: "Author Guidelines" }],
        },
        component: () => import("../views/page/journalDetail/authorGuidelines"),
      },
      {
        path: "Authorship",
        name: "Authorship",
        meta: {
          bread: [
            { name: "home", link: "/" },
            { name: "journals", link: "/journalList" },
          ],
          current: [{ name: "Authorship" }],
        },
        component: () => import("../views/page/journalDetail/Authorship"),
      },
      {
        path: "ConflictOfInterest",
        name: "ConflictOfInterest",
        meta: {
          bread: [
            { name: "home", link: "/" },
            { name: "journals", link: "/journalList" },
          ],
          current: [{ name: "ConflictOfInterest" }],
        },
        component: () =>
          import("../views/page/journalDetail/ConflictOfInterest"),
      },
      {
        path: "ensuringBlindReview",
        name: "EnsuringBlindReview",
        meta: {
          bread: [
            { name: "home", link: "/" },
            { name: "journals", link: "/journalList" },
          ],
          current: [{ name: "EnsuringBlindReview" }],
        },
        component: () =>
          import("../views/page/journalDetail/ensuringBlindReview"),
      },
      {
        path: "current",
        name: "Current",
        meta: {
          bread: [
            { name: "home", link: "/" },
            { name: "journals", link: "/journalList" },
          ],
          current: [{ name: "Current" }],
        },
        component: () => import("../views/page/journalDetail/currentPage"),
      },
      {
        path: "archives",
        name: "Archives",
        meta: {
          bread: [
            { name: "home", link: "/" },
            { name: "journals", link: "/journalList" },
          ],
          current: [{ name: "Archives" }],
        },
        component: () => import("../views/page/journalDetail/archivesPage"),
      },
      {
        path: "articalDetail",
        name: "Artical Detail",
        meta: {
          bread: [
            { name: "home", link: "/" },
            { name: "journals", link: "/journalList" },
          ],
          current: [{ name: "Artical Detail" }],
        },
        component: () => import("../views/page/journalDetail/articalDetail"),
      },
      {
        path: "articleProcessingCharge",
        name: "Article Processing Charge",
        meta: {
          bread: [
            { name: "home", link: "/" },
            { name: "journals", link: "/journalList" },
          ],
          current: [{ name: "Article Processing Charge" }],
        },
        component: () =>
          import("../views/page/journalDetail/articleProcessingCharge"),
      },
      {
        path: "submissions",
        name: "submissionDetail",
        meta: {
          bread: [
            { name: "home", link: "/" },
            { name: "journals", link: "/journalList" },
          ],
          current: [{ name: "submissionDetail" }],
        },
        component: () =>
          import("../views/page/submissions/submissionDetail.vue"),
      },
      {
        path: "submissionDetailView",
        name: "submissionDetailView",
        meta: {
          bread: [
            { name: "home", link: "/" },
            { name: "journals", link: "/journalList" },
          ],
          current: [{ name: "submissionDetailView" }],
        },
        component: () =>
          import("../views/page/submissions/submissionDetailView.vue"),
      },
      {
        path: "wizard",
        name: "wizard",
        meta: {
          bread: [
            { name: "home", link: "/" },
            { name: "journals", link: "/journalList" },
          ],
          current: [{ name: "wizard" }],
        },
        component: () => import("../views/page/submissions/submitArticle.vue"),
      },
      // {
      //   path: ':childAbout',
      //   name: 'about',
      //   meta: { bread: [{ name: "home", link: "/" }, { name: "journals", link: "/journalList" }], current: [{ name: "about" }] },
      //   component: () => import("../views/page/journalDetail/allAbout")
      // },
    ],
  },
  {
    path: "/aboutView",
    name: "aboutView",
    component: () => import("../views/page/aboutView.vue"),
  },
  {
    path: "/forAuthors",
    name: "forAuthors",
    meta: { bread: [{ name: "home", link: "/" }, { name: "forAuthors" }] },
    component: () => import("../views/page/forAuthors.vue"),
  },
  {
    path: "/forReviewers",
    name: "forReviewers",
    meta: { bread: [{ name: "home", link: "/" }, { name: "forReviewers" }] },
    component: () => import("../views/page/forReviewers.vue"),
  },
  {
    path: "/forEditors",
    name: "forEditors",
    meta: { bread: [{ name: "home", link: "/" }, { name: "forEditors" }] },
    component: () => import("../views/page/forEditors.vue"),
  },
  {
    path: "/editorialPublishing",
    name: "editorialPublishing",
    component: () => import("../views/page/editorialPublishing.vue"),
  },
  {
    path: "/announcementsList",
    name: "announcementsList",
    component: () => import("../views/page/announcementsList.vue"),
  },
  {
    path: "/announcementsDetail",
    name: "announcementsDetail",
    meta: {
      bread: [
        { name: "home", link: "/" },
        { name: "announcementsList", link: "/announcementsList" },
        { name: "announcementsDetail" },
      ],
    },
    component: () => import("../views/page/announcementsDetail.vue"),
  },
  {
    path: "/search",
    name: "search",
    component: () => import("../views/page/searchPage.vue"),
  },
  {
    path: "/regist",
    name: "regist",
    component: () => import("../views/page/regist.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/page/login.vue"),
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("../views/page/profile.vue"),
  },
  {
    path: "*",
    component: () => import("../views/page/noPage.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});
let originPush = VueRouter.prototype.push;

VueRouter.prototype.push = function (location, resolve, reject) {
  if (resolve && reject) {
    originPush.call(this, location, resolve, reject);
  } else {
    originPush.call(
      this,
      location,
      () => {},
      () => {}
    );
  }
};
export default router;
