import enLocale from "element-ui/lib/locale/lang/en"; //引入element语言包
const en = {
  homeHeader: {
    dropdown: {
      dashboard: "Dashboard",
      viewProfile: "View Profile",
      logout: "Logout",
    },
    login: "LOGIN",
    register: "REGISTER",
  },
  footer: {
    info: "Yibo Research&Academic Press （YRP）was officially established in Singapore With a global orientation. Actively expands our business territory globally. Our core businesses mainly focus on publishing academic journals and organizing international academic conferences. In addition, our business also widely covers diversified fields such as education, scientific and technological information consulting, translation services, plagiarism - checking services and e - book publishing.",
    Address: "12 WOODLANDS SQUARE #12-85 WOODS SQUARE SINGAPORE 737715",
    tel: "+65 9863 3408",
    email: "yiborap@outlook.com",
    author:
      "Yibo Research Academic Press Pte Ltd © 2019-2025 All Right Reserved",
  },
  homeNav: [
    { name: "Home", path: "/" },
    { name: "Journals", path: "/journalList" },
    {
      name: "Editorial and Publishing Policies",
      path: "/editorialPublishing",
    },
    // { name: "Partners", path: "/" },
    { name: "News & Announcements", path: "/announcementsList" },
    { name: "About", path: "/aboutView" },
  ],
  homeView: {
    banner: {
      title: "Yibo Research Academic Press",
      subTitle:
        "Our core business mainly focuses on publishing academic journals and organizing international academic conferences",
    },
    search: {
      placeholder: "Please enter the content",
      journal: "Journal",
      article: "Article",
      keyword: "keyword",
    },
    content: {
      journal: "Journals",
      articles: "Latest Articles",
      more: "more journals",
    },
  },
  asidPart: {
    classification: "Journals",
    asidNavList: [
      // { navItem: "Information", url: "" },
      { navItem: "For authors", url: "/forAuthors" },
      { navItem: "For reviewers", url: "/forReviewers" },
      { navItem: "For editors", url: "/forEditors" },
    ],
    asidTitle: "News & Announcements",
    detail: "detail",
    more: "more",
  },
  journalList: {
    title: "Open Access Journals",
    viewJournal: "View Journal",
    currentIssue: "Current Issue",
  },
  abouView: {
    title: "About Yibo Research&Academic Press",
    Singapore: "Singapore",
  },
  detailPages: {
    OpenAccess: "Open Access",
    PublicationFrequency: "Publication Frequency",
    Online: "Online",
    Articles: "Article",
    onBoard: "on board",
    editors: "editors",
    placeholder: "Search in this journal",
    submit: "Submit a Paper",
    menu: "Journal Menu",
    tableContent: "Table of Content",
    aboutJournal: "About the Journal",
    indexAbstracting: "Indexing and Abstracting",
    articleTypeList: ["Latest Articles"],
    more: "More",
    vol: "vol.",
    No: "No.",
    view: "view",
    downloads: "downloads",
    readMore: "Read More",
    clickHide: "Click Hide",
    pdfDownload: "PDF Downloads",
    Keywords: "Keywords",
    downloadsPdf: "Download PDF",
    Abstract: "Abstract",
    References: "References",
    Published: "Published",
    cite: "How to Cite",
    selectStyle: "Select citation style",
    Issue: "Issue",
    Section: "Section",
    copyRight:
      "copyright © Author in 2024. Published by Yibo Research Academic Press.",
  },
  shareLink: "Share Link",
  copy: "copy",
  authorGuidelines: {
    checkList: "Submission Preparation Checklist",
    guidelines: "Author Guidelines",
    copyright: "Copyright Notice",
    statement: "Privacy Statement",
  },
  login: {
    title: "Sign In",
    username: "Username",
    password: "Password",
    forgetPassword: "Forgot your password?",
    register: "Register",
    usernameMessage: "username is required",
    passwordMessage: "password is required",
    keep: " Keep me logged in",
    resetPassword: "Reset Password",
    resetPasswordMessage:
      "A confirmation has been sent to your email address. Please follow the instructions in the email to reset your password.",
    restLabel:
      "Enter your account email address below and an email will be sent with instructions on how to reset your password.Registered user's email",
    restPlacdholder: "Enter your email",
  },
  register: {
    Profile: "Profile",
    givenName: "Given Name",
    familyName: "Family Name",
    affiliation: "Affiliation",
    country: "Country/Region",
    email: "Email",
    repeatPassword: "Repeat password",
    Journal: "Journal",
    check:
      " Yes, I would like to be notified of new publications andannouncements.",
    login: "login",
  },
  form: {
    required: "This field is required.",
    email: "Enter the correct email address.",
    url: "Please enter a valid URL",
    passwordAgain: "Please enter your password again",
    passwordDiffer: "Entered passwords differ!",
    passwordLength: "Password must be at least 6 characters",
  },
  searchPage: {
    Title: "Title",
    Keywords: "Keywords",
    Journal: "Journal",
    PublishedAfter: "Published After",
    PublishedBefore: "Published Before",
    Author: "Author",
    UpdateSearching: "Update Searching",
    clear: "clear",
    articleTypeList: ["Articles"],
  },
  submission: {
    title: "Submissions",
    search: "Search",
    assigned: "My Assigned",
    archived: "Archived Submissions",
    newSubmission: "New Submission",
    viewSubmission: "View Submission",
    deleteSubmission: "Delete",
    edit: "edit",
    queue: "My Queue",
    Archives: "Archives",
    Section: "Section",
    sectionLable:
      "Articles must be submitted to one of the journal's sections.",
    selectSection: "Select Section",
    sectionPolicy: "Section Policy",
    Requirements: "Submission Requirements",
    helpText:
      " You must read and acknowledge that you've completed the requirements below before proceeding.",
    Editor: "Comments for the Editor",
    privacyConsent:
      "Yes, I agree to have my data collected and stored according to the",
    privacyStatementLink: "privacy statement",
    privacyStatement: "",
    continue: "Save and continue",
    submit: "Submit",
    Cancel: "Cancel",
    secondTitle: "Submission Files",
    UploadFile: "Upload File",
    downloadAllFiles: "Download All Files",
    viewMetadata: "View Metadata",
    editFile: "Edit a file",
    save: "Save",
    Continue: "continue",
    uploadSubmissionFile: "Upload Submission File",
    fileStepFirst: "1. Upload File",
    fileStepSecond: "2. Review Details",
    fileStepthird: "3. Confirm",
    revisedLabel:
      "If you are uploading a revision of an existing file, please indicate which file.",
    revisedPlaceholder: "This is not a revision of an existing file",
    genrePlaceholder: "Select article component",
    Drag: "Drag the file here, or",
    clickUpload: "Click to upload",
    fileAdd: "File Added",
    addAnotherFile: "Add Another File",
    Prefix: "Prefix",
    Title: "Title",
    Subtitle: "Subtitle",
    listContributors: "List of Contributors",
    addContributor: "Add Contributor",
    editContributor: "Edit Contributor",
    fullName: "Name",
    email: "E-mail",
    userGroupName: "Role",
    primaryContact: "Primary Contact",
    inBrowseLists: "In Browse Lists",
    SubmissionMetadata: "Submission Metadata",
    specifications:
      "  These specifications are based on the Dublin Core metadata set, an international standard used to describe journal content.",
    additionalRefinements: "Additional Refinements",
    newKeywords: "New Keywords",
    ProjectID: "OpenAIRE ProjectID",
    research:
      "If this research resulted from an EU initiative that complies with the",
    OpenAIRE: "OpenAIRE",
    platform:
      " metadata platform, please include the ProjectID, a six-digit number which corresponds with the Grant Agreement identifier.",
    Salutations:
      "How do you prefer to be addressed? Salutations, middle names and suffixes can be added here if you would like.",
    publicName: "Preferred Public Name",
    Contact: "Contact",
    userDetails: "User Details",
    HomepageURL: "Homepage URL",
    ORCID: "See below to request authenticated ORCID iD ORCID iD",
    department: "Bio Statement (e.g., department and rank)",
    Translator: "Translator",
    contributorRole: "Contributor's role",
    Principal: "Principal contact for editorial correspondence.",
    browse: "Include this contributor in browse lists?",
    Confirmation:
      'Your submission has been uploaded and is ready to be sent. You may go back to review and adjust any of the information you have entered before continuing. When you are ready, click "Finish Submission".',
    finishSubmission: "Finish Submission",
    SubmissionComplete: "Submission complete",
    Population:
      " Thank you for your interest in publishing with Global Population Perspectives.",
    Happens: "What Happens Next?",
    notified:
      "The journal has been notified of your submission, and you've been emailed a confirmation for your records. Once the editor has reviewed the submission, they will contact you.",
    youCan: "For now, you can: ",
    Review: "Review this submission",
    Create: "Create a new submission",
    returnDashboard: "Return to your dashboard",
    submitArticle: "Submit an Article",
    articleComponent: "Article Component",
    steps: {
      step1: "1. Start",
      step2: "2. Upload Submission",
      step3: "3. Enter Metadata",
      step4: "4. Confirmation",
      step5: "5. Next Steps",
    },
    preDiscussions: "Pre-Review Discussions",
    ReviewDiscussions: "Review Discussions",
    CopyeditingDiscussions: "Copyediting Discussions",
    ProductionDiscussions: "Production Discussions",
    addDiscussions: "Add discussion",
    From: "From",
    lastReply: "Last Reply",
    Replies: "Replies",
    Closed: "Closed",
    Edit: "Edit",
    Stage: "Stage not initiated.",
    reviewerAttachments: "Reviewer's Attachments",
    Revisions: "Revisions",
    Copyedited: "Copyedited",
    Galleys: "Galleys",
    stages: {
      Submission: "Submission",
      Review: "Review",
      Copyediting: "Copyediting",
      Production: "Production",
      Published: "Published",
      Declined: "Declined",
      Incomplete: "Incomplete",
    },
    discussion: {
      adddiscussion: "Add discussion",
      updateDiscussion: "Update Discussion",
      Participants: "Participants",
      Subject: "Subject",
      message: "message",
      secondTitle: "Attached Files",
    },
    Comment: {
      Note: "Note",
      addMessage: "Add message",
      Message: "Message",
    },
  },
  Prompt: {
    title: "prompt",
    content: "This operation cannot be undone. Do you want to continue?",
    sure: "sure",
    cancel: "cancel",
    success: "Operation successful!",
    info: "Cancelled",
    imageType: "The uploaded avatar image can only be in JPG/PNG format!",
    imageSize: "The uploaded image must be 150x150 pixels!",
  },
  profile: {
    statement: "Your data is stored in accordance with our privacy statement.",
    Signature: "Signature",
    Phone: "Phone",
    mailingAddress: "Mailing Address",
    Interests: "New Interests",
    ReInterests: "Reviewing interests",
    profileImage: "Profile Image",
    passwords:
      "Enter your current and new passwords below to change the password for your account.",
    currentPassword: "Current Password",
    newPassword: "New Password",
    repeatPassword: "Repeat new password",
    system:
      "Select the system events that you wish to be notified about. Unchecking an item will prevent notifications of the event from showing up in the system and also from being emailed to you. Checked events will appear in the system and you have an extra option to receive or not the same notification by email.",
    enablenotifications: "Enable these types of notifications.",
    notifications: "Do not send me an email for these types of notifications.",
    Generate: "Generate new API key",
    invalidate:
      "Generating a new API key will invalidate any existing key for this user.",
    tab: {
      Identity: "Identity",
      Contact: "Contact",
      Roles: "Roles",
      Public: "Public",
      Password: "Password",
      Notifications: "Notifications",
      apiKeys: "API Key",
    },
  },
  empty: "No Data",
  menuList: [
    "Editorial Board",
    "About the Journal",
    "Indexing & Archiving",
    "Contact Information",
    "Author Guidelines",
    "Article Processing Charge",
    "Current",
    "Authorship",
    "Conflict of Interest",
    "Ensuring a Blind Review",
    "Privacy Statement",
  ],
  ...enLocale, //这里引入element语言包目的是在切换语言的时候，element的组件里面的文字能跟随切换
};
export default en;
