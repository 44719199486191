import axios from "axios";
import { Message } from "element-ui";
const service = axios.create({
  baseURL: "https://ojs.yibo-pub.com/index.php/",
  // baseURL: "http://ceshi.acad-pub.com/index.php/",
  timeout: 10000,
});
service.interceptors.request.use(
  (config) => {
    const language = localStorage.getItem("langString"); // 获取当前语言，可能是 'en' 或 'zh'
    if (language) {
      if (config.params) {
        config.params.uiLocale = language;
      } else {
        config.params = { uiLocale: language };
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
service.interceptors.response.use(function (response) {
  const contentType = response.headers["content-type"];
  if (contentType && contentType.includes("application/pdf")) {
    return response;
  } else if (response.status == 200) {
    const res = response.data;
    if (res.code == 200 || res.code == 401) {
      return res;
    } else {
      Message({
        type: "error",
        message: res.message || "请求错误",
      });
      return Promise.reject(new Error(res.message || "请求错误"));
    }
  }
}),
  function (error) {
    Message({
      type: "error",
      message: error.message || "请求错误",
    });
    return Promise.reject(new Error("发送请求失败"));
  };
export default service;
